import lazyLoader from 'helpers/lazy-loader';

const RetailerListLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "retailer-list" */ './retailer-list')
);

const RetailerList = (props: any) => {
  return <RetailerListLoadable {...props} />;
};

export default RetailerList;
