import 'assets/scss/quill.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';

import 'helpers/sentry';

import App from './App';
import './index.scss';

if (import.meta.env.VITE_MODE !== 'local') {
  ReactGA.initialize('G-KYGM88MVGH');
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
