import lazyLoader from 'helpers/lazy-loader';

const ProductListLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "product-management" */ './product-management')
);

const ProductList = (props: any) => {
  return <ProductListLoadable {...props} />;
};

export default ProductList;
