import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getUserDetail } from 'api/services/auth';

import { COOKIE_ACCESS_TOKEN, RQ_CURRENT_USER } from 'helpers/constants';
import { getCookie } from 'helpers/cookie';

import { AuthContextState } from './AuthContext.interface';

const initialAuthData = {
  isLogin: false,
  user: {
    id: 0,
    email: '',
    name: '',
    role: {
      id: 0,
      name: ''
    }
  },
  isFetched: false
};

const initialData = {
  data: initialAuthData,
  setAuthData: () => {},
  refetch: () => {}
};

export const AuthContext = createContext<AuthContextState>(initialData);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useLocation();
  const [authData, setAuthData] = useState(initialAuthData);
  const hasToken = getCookie(COOKIE_ACCESS_TOKEN);

  const { data, refetch } = useQuery({
    queryKey: [RQ_CURRENT_USER],
    queryFn: getUserDetail,
    enabled: !!hasToken && pathname !== '/login'
  });

  useEffect(() => {
    const result = data?.data;
    if (result) {
      setAuthData(result);
    }
  }, [data]);

  return (
    <AuthContext.Provider value={{ data: authData, setAuthData, refetch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authData = useContext(AuthContext);

  if (!authData) {
    throw new Error('useAuth should be called inside AUthContextProvider!');
  }

  return authData;
};
