import lazyLoader from 'helpers/lazy-loader';

const UserFormLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "user-form" */ './user-form')
);

const UserForm = (props: any) => {
  return <UserFormLoadable {...props} />;
};

export default UserForm;
