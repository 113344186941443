import { memo, useEffect, useState } from 'react';

import type { TextFieldProps } from './types';

const TextField = ({
  label,
  type = 'text',
  className,
  name,
  onInput,
  defaultValue,
  disabled,
  placeholder,
  pattern,
  title,
  required,
  message,
  maxLength,
  onChange,
  onFocus,
  autoComplete,
  max,
  min,
  minLength,
  isToUpperCase,
  onKeyDown,
  onEnter,
  onBlur
}: TextFieldProps) => {
  const [value, setValue] = useState<string | number>(defaultValue || '');

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type !== 'yearPicker') {
      if (e?.target?.value?.length === 0 && required) {
        e.target.setCustomValidity('Silahkan isi field ini!');
      } else {
        e.target.setCustomValidity('');
      }
    }
    if (type === 'number' && maxLength) {
      if (e.target.value.toString().length > maxLength) return;
    }
    if (onChange) {
      onChange(e);
    } else if (isToUpperCase) {
      setValue(e.target.value.toUpperCase());
      if (onInput) onInput(e);
    } else {
      setValue(e.target.value);
      if (onInput) onInput(e);
    }
  };

  const handleFocus = () => {
    if (onFocus) onFocus();
  };

  return (
    <>
      <div className={`${className}`}>
        <div className="field">
          {label ? (
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor={name}
            >
              {label} {required ? <b className="text-error-main">*</b> : ''}
            </label>
          ) : null}
          <div className={'relative w-full h-full flex input-base'}>
            <input
              id={name}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && onEnter) {
                  onEnter(e, value);
                }

                if (onKeyDown) {
                  onKeyDown(e);
                }
              }}
              autoComplete={autoComplete ?? 'false'}
              max={max}
              min={min}
              onFocus={handleFocus}
              maxLength={maxLength}
              minLength={minLength ?? 0}
              title={title}
              pattern={pattern}
              required={required}
              type={type}
              name={name}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:outline-blue-500 dark:focus:border-blue-500"
              value={value}
              disabled={disabled}
              onInput={handleInput}
              placeholder={placeholder}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
      {message ? <p className="text-xs ml-2 text-red-500 p-0 m-0">{message}</p> : null}
    </>
  );
};

export default memo(TextField);
