import lazyLoader from 'helpers/lazy-loader';

const UserManagementLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "user-management" */ './user-management')
);

const UserManagement = (props: any) => {
  return <UserManagementLoadable {...props} />;
};

export default UserManagement;
