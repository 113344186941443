import lazyLoader from 'helpers/lazy-loader';

const HomePageLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "merge-suggestion" */ './home-page')
);

const HomePage = (props: any) => {
  return <HomePageLoadable {...props} />;
};

export default HomePage;
