import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MdOutlineArrowDropDown, MdOutlineClose } from 'react-icons/md';

import Portal from 'components/portal';
import TextField from 'components/text-field';

import { removeDuplicates } from 'helpers/array';

import type { SelectOption, SelectProps } from './types';

const Combobox = ({
  label,
  labelClassName,
  className,
  disabled,
  options,
  defaultValue,
  boldDefaultValue = false,
  placeholder,
  clearable,
  itemValue,
  itemText,
  inputClassName,
  smaller,
  multiple,
  searchable,
  placement = 'bottom-start',
  isFetchingNextPage,
  fetchNextPage,
  id,
  onChange,
  error
}: SelectProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const dropdownEl = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState<SelectOption[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const valueArr = defaultValue instanceof Array ? defaultValue : [defaultValue];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (valueArr && options?.length > 0) {
      const selectedItems = options.filter((item) => {
        const itemId = itemValue ? item[itemValue as keyof SelectOption] : item.id;
        return valueArr.includes(itemId);
      });
      setValues(selectedItems);
    } else {
      setValues([]);
    }
  }, [defaultValue, options]);

  const handleSelectItem = (item: SelectOption | null) => {
    setSearchKeyword('');
    setValues((prev) => removeDuplicates([...prev, item]));
    if (onChange) {
      const newValue = itemValue ? item?.[itemValue as keyof SelectOption] || null : item?.id;
      if (multiple) {
        const isExist = valueArr.includes(newValue);
        const newArr = valueArr.filter((item) => (isExist ? item !== newValue : true));
        onChange(
          valueArr ? removeDuplicates(isExist ? newArr : [...valueArr, newValue]) : [newValue]
        );
      } else {
        onChange(newValue || null);
      }
    }

    if (!multiple) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (id && fetchNextPage) {
      const optionList = document.getElementById(`options-container-${id}`);
      const onScroll = () => {
        const lastChildBottom =
          optionList?.children[optionList.children.length - 1]?.getBoundingClientRect()?.bottom ||
          0;
        const optionListBottom = optionList?.getBoundingClientRect()?.bottom || 0;
        if (lastChildBottom - optionListBottom < 300 && !isFetchingNextPage) {
          fetchNextPage();
        }
      };
      setTimeout(() => {
        optionList?.addEventListener('scroll', onScroll);
      });
      return () => optionList?.removeEventListener('scroll', onScroll);
    }
  }, [fetchNextPage, id, isOpen, isFetchingNextPage]);

  const getAnchorPosition = () => {
    if (anchorEl.current && dropdownEl.current) {
      const anchorRect = anchorEl.current.getBoundingClientRect();
      const gapSize = 10;
      const width = `${anchorEl.current.offsetWidth}px`;

      // this is to handle when the dropdown is on the bottom of the page (it being cropped by the viewport), hence will automatically move the placement to the top of the anchor
      const isOverViewport =
        anchorRect.bottom + dropdownEl.current.clientHeight > window.innerHeight;
      const topOverViewport = `${
        anchorRect.y + window.scrollY - dropdownEl.current.clientHeight + gapSize
      }px`;
      const topOverViewportInt =
        anchorRect.y + window.scrollY - dropdownEl.current.clientHeight + gapSize;
      const topPosition = anchorRect.bottom + window.scrollY + gapSize;

      if (placement === 'bottom-end') {
        return {
          top: isOverViewport ? topOverViewport : `${topPosition}px`,
          right: `${window.innerWidth - anchorRect.right}px`,
          width
        };
      }
      if (placement === 'bottom-start') {
        return {
          top: isOverViewport ? topOverViewport : `${topPosition}px`,
          left: `${anchorRect.left}px`,
          width
        };
      }
      if (placement === 'bottom') {
        const centerDropdown = dropdownEl.current.offsetWidth / 2;
        const centerAnchor = anchorRect.width / 2;

        return {
          top: isOverViewport ? topOverViewport : `${topPosition}px`,
          left: `${anchorRect.left + centerAnchor - centerDropdown}px`,
          width
        };
      }
      if (placement === 'top') {
        return {
          top: `${topOverViewportInt - (dropdownEl.current?.offsetHeight || 100)}px`,
          left: `${anchorRect.left}px`,
          width
        };
      }
    }

    return { bottom: '100%' };
  };

  const selectedValues = useMemo(
    () =>
      values
        .map((item) => (itemText ? item?.[itemText as keyof SelectOption] : item?.name || ''))
        .join(', '),
    [values, itemText]
  );

  return (
    <div className={`input-base ${className || ''}`}>
      <div className="field">
        {label ? (
          <label
            className={
              labelClassName || 'block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            }
          >
            {label}
          </label>
        ) : null}
        <div className="relative" ref={dropdownRef}>
          <button
            ref={anchorEl}
            disabled={disabled}
            data-dropdown-trigger="hover"
            type="button"
            onClick={toggleDropdown}
            className={`${
              smaller ? 'min-h-[36px] px-2.5 py-0' : 'min-h-11 p-2.5'
            } bg-gray-50 border ${
              error ? 'border-red-500 border-2' : 'border-gray-300'
            } text-gray-900 text-sm rounded-lg focus-visible:outline-blue-500 focus-visible:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus-visible:outline-blue-500 dark:focus-visible:border-blue-500
            ${inputClassName || ''}
            `}
          >
            <div className="flex justify-between items-center">
              <div
                className={`text-left w-[100%-20px] ${
                  boldDefaultValue && 'text-green-400 font-bold'
                }`}
                dangerouslySetInnerHTML={{
                  __html: selectedValues || placeholder || ''
                }}
              />
              {selectedValues.length > 0 && clearable ? (
                <MdOutlineClose
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectItem(null);
                  }}
                />
              ) : (
                <MdOutlineArrowDropDown size={20} />
              )}
            </div>
          </button>
          {error ? <div className="text-danger text-sm">{error}</div> : null}
          {isOpen && (
            <div
              className="fixed top-0 left-0 right-0 bottom-0 z-10"
              onClick={() => setIsOpen(false)}
            />
          )}
          <Portal>
            <div
              ref={dropdownEl}
              style={getAnchorPosition()}
              className={`z-[60] absolute ${
                isOpen ? '' : 'hidden'
              } bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 shadow-lg`}
            >
              <ul
                className="text-sm text-gray-700 dark:text-gray-200 w-full max-h-[400px] overflow-y-auto"
                id={`options-container-${id}`}
              >
                {searchable && (
                  <li
                    className={`text-left block px-4 py-2 dark:hover:bg-gray-600 sticky top-0 bg-white dark:bg-gray-700`}
                  >
                    <TextField
                      defaultValue={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      placeholder="Search"
                    />
                  </li>
                )}
                {options?.map((item, index) => {
                  const itemId = itemValue ? item[itemValue as keyof SelectOption] : item.id;
                  const text = String(itemText ? item[itemText as keyof SelectOption] : item.name);

                  if (searchable) {
                    if (searchKeyword && !text.toLowerCase().includes(searchKeyword)) {
                      return null;
                    }
                  }

                  return (
                    <li
                      key={index}
                      className={`cursor-pointer text-left block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white ${
                        valueArr.includes(itemId) ? 'bg-gray-100 dark:bg-slate-600' : ''
                      }`}
                      onClick={() => handleSelectItem(item)}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: text
                        }}
                      />
                    </li>
                  );
                })}
                {isFetchingNextPage && (
                  <li className="animate-pulse cursor-pointer text-left block px-4 py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    <div className="bg-gray-200 rounded-lg h-[30px] w-full px-2 py-2 text-xs">
                      Loading...
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </Portal>
        </div>
      </div>
    </div>
  );
};

export default memo(Combobox);
