import { useLoadingStore } from 'store/loading';

const Loader = () => {
  const showLoading = useLoadingStore((state) => state.showLoading);

  if (!showLoading) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-[60] flex h-screen items-center justify-center bg-grey-600 bg-opacity-60 transition-opacity">
      <div className="bg-white rounded-full p-3">
        <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
      </div>
    </div>
  );
};

export default Loader;
