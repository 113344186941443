import { fetchAuth } from 'api/config';

export interface GetBrandReportParam {
  //   page: number;
  //   per_page: number;
  //   keyword: string | undefined;
  filters: any[];
}

interface Total {
  total_cost: number;
  total_order: number;
}

interface CountryStats {
  [key: string]: Total[]; // This indicates that each key (country) will have an array of Total objects
  grand_total: Total[]; // This ensures that 'grand_total' is also of type Total
  other: Total[];
}

interface BrandCountryData {
  id: null;
  name: null;
  countries: CountryStats;
  grand_total?: {
    total_cost?: number;
    total_order?: number;
  };
  total_cost: number;
  total_order: number;
}

interface CategoryCountryData {
  id: null;
  category: null;
  countries: CountryStats;
  grand_total?: {
    total_cost?: number;
    total_order?: number;
  };
  total_cost: number;
  total_order: number;
}

export interface GetBrandReportResponse {
  status_code: number;
  message: string;
  metadata: null;
  data: {
    top_brands_by_gender: Array<{
      id: null;
      brand_name: null;
      gender: {
        male: Total;
        female: Total;
        unisex: Total;
      };
      total: Total;
    }>;
    top_orders_by_gender: Array<{
      gender: null;
      orders_revenue: number;
      orders_count: number;
    }>;
    top_category_by_gender: Array<{
      id: null;
      category: null;
      gender: {
        male: Total;
        female: Total;
        unisex: Total;
      };
      total: Total;
    }>;
    top_orders_by_country: Array<{
      user_shipping_country: null;
      orders_revenue: number;
      orders_count: number;
    }>;
    top_brands_by_country: BrandCountryData[];
    top_category_by_country: CategoryCountryData[];
  };
}

export const getBrandReport = async (
  params: GetBrandReportParam
): Promise<GetBrandReportResponse> => {
  return await fetchAuth({
    url: `/api/admin/brand-report/datatable`,
    method: 'GET',
    params
  });
};
