import lazyLoader from 'helpers/lazy-loader';

const OrderListLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "order-list" */ './order-list')
);

const OrderList = (props: any) => {
  return <OrderListLoadable {...props} />;
};

export default OrderList;
