import { fetchAuth } from 'api/config';
import {
  GetCategoryListResponse,
  GetConfigDisplayResponse,
  GetLabelListResponse,
  GetRetailerListResponse,
  GetSeasonListResponse,
  GetSupplierListResponse,
  GetVendorListResponse
} from 'api/types/common';

export const getVendorList = async (): Promise<GetVendorListResponse> => {
  return await fetchAuth({
    url: '/v1/brands',
    method: 'GET',
    params: {
      fields: ['id', 'name']
    }
  });
};

export const getLabelList = async (): Promise<GetLabelListResponse> => {
  return await fetchAuth({
    url: '/v1/labels',
    method: 'GET',
    params: {
      fields: ['id', 'name']
    }
  });
};

export const getConfigDisplay = async (
  path: string,
  userId: number
): Promise<GetConfigDisplayResponse> => {
  return await fetchAuth({
    url: '/v1/config-display/' + path + '/' + userId.toString(),
    method: 'GET'
  });
};

export const postConfigDisplay = async (payload: {
  path: string;
  userId: number;
  value: string[];
}): Promise<GetConfigDisplayResponse> => {
  const { path, userId, value } = payload;
  return await fetchAuth({
    url: '/v1/config-display',
    method: 'POST',
    body: {
      user_id: userId.toString(),
      path,
      value
    }
  });
};

export const putConfigDisplay = async (payload: {
  path: string;
  userId: number;
  value: string[];
}): Promise<GetConfigDisplayResponse> => {
  const { path, userId, value } = payload;
  return await fetchAuth({
    url: '/v1/config-display/' + path + '/' + userId.toString(),
    method: 'PUT',
    body: {
      value
    }
  });
};

export const getSeasonList = async (): Promise<GetSeasonListResponse> => {
  return await fetchAuth({
    url: '/v1/seasons',
    method: 'GET',
    params: {
      fields: ['id', 'name']
    }
  });
};

export const getSupplierList = async (): Promise<GetSupplierListResponse> => {
  return await fetchAuth({
    url: '/v1/suppliers',
    method: 'GET',
    params: {
      fields: ['id', 'name']
    }
  });
};

export const getRetailerList = async (): Promise<GetRetailerListResponse> => {
  return await fetchAuth({
    url: '/v1/retailers',
    method: 'GET',
    params: {
      fields: ['id', 'name']
    }
  });
};

export const getCategoryList = async (): Promise<GetCategoryListResponse> => {
  return await fetchAuth({
    url: '/v1/products/categories',
    method: 'GET'
  });
};
