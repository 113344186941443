import lazyLoader from 'helpers/lazy-loader';

const SalesReportLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "sales-report" */ './sales-report')
);

const SalesReport = (props: any) => {
  return <SalesReportLoadable {...props} />;
};

export default SalesReport;
