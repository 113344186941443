import lazyLoader from 'helpers/lazy-loader';

const MergeSuggestionLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "merge-suggestion" */ './merge-suggestion')
);

const MergeSuggestion = (props: any) => {
  return <MergeSuggestionLoadable {...props} />;
};

export default MergeSuggestion;
