import lazyLoader from 'helpers/lazy-loader';

const ProductFormLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "product-form" */ './product-form')
);

const ProductForm = (props: any) => {
  return <ProductFormLoadable {...props} />;
};

export default ProductForm;
