import { create } from 'zustand';

export interface useLoadingStoreInterface {
  showLoading: boolean;
  setShowLoading: (isLoading: boolean) => void;
}

export const useLoadingStore = create<useLoadingStoreInterface>((set) => ({
  showLoading: false,
  setShowLoading: (isLoading: boolean) => set({ showLoading: isLoading })
}));
