import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  pageName: string;
  pageMeta?: React.ReactNode;
  items?: Array<{
    label: string;
    href: string;
  }>;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageName, items, pageMeta }) => {
  return (
    <div className="mb-4 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
      <div className="flex">
        <h2 className=" text-title-md2 font-semibold text-black dark:text-white">{pageName}</h2>{' '}
        {pageMeta}
      </div>
      {items ? (
        <nav>
          <ol className="flex items-center gap-2">
            {items.map((item, index) => (
              <li key={index}>
                {index === items.length - 1 ? (
                  <span>{item.label}</span>
                ) : (
                  <>
                    <Link className="text-primary" to={item.href}>
                      {item.label}
                    </Link>
                    &nbsp;/
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      ) : null}
    </div>
  );
};

export default Breadcrumb;
