import lazyLoader from 'helpers/lazy-loader';

const ProductListSuggestionLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "product-list-suggestion" */ './product-list')
);

const ProductListSuggestion = (props: any) => {
  return <ProductListSuggestionLoadable {...props} />;
};

export default ProductListSuggestion;
