import lazyLoader from 'helpers/lazy-loader';

const OrderDetailLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "order-detail" */ './order-detail')
);

const OrderDetail = (props: any) => {
  return <OrderDetailLoadable {...props} />;
};

export default OrderDetail;
