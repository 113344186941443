import lazyLoader from 'helpers/lazy-loader';

const SettingsLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "settings" */ './settings')
);

const Settings = (props: any) => {
  return <SettingsLoadable {...props} />;
};

export default Settings;
