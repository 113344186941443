import lazyLoader from 'helpers/lazy-loader';

const ActivityLogLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "activity-log" */ './activity-log')
);

const ActivityLog = (props: any) => {
  return <ActivityLogLoadable {...props} />;
};

export default ActivityLog;
