export const arraysAreEqual = (array1: any, array2: any) => {
  if (array1.length !== array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (JSON.stringify(array1[i]) !== JSON.stringify(array2[i])) {
      return false;
    }
  }
  return true;
};

export const removeDuplicatesByKey = (array: any, key: string) => {
  const unique = array.reduce(
    (accumulator: any, current: any) => {
      if (!accumulator.seen.has(current[key])) {
        accumulator.seen.add(current[key]);
        accumulator.array.push(current);
      }
      return accumulator;
    },
    { array: [], seen: new Set() }
  ).array;

  return unique;
};

export const removeDuplicates = (array: any) => {
  const unique = array.reduce(
    (accumulator: any, item: string | number) => {
      if (!accumulator.seen.has(item)) {
        accumulator.seen.add(item);
        accumulator.array.push(item);
      }
      return accumulator;
    },
    { array: [], seen: new Set() }
  ).array;

  return unique;
};

export const removeObjectByKey = (object: any, key: string) => {
  const { [key]: _, ...rest } = object;
  return rest;
};
