import lazyLoader from 'helpers/lazy-loader';

const MappingSizeLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "merge-suggestion" */ './mapping-size')
);

const MappingSize = (props: any) => {
  return <MappingSizeLoadable {...props} />;
};

export default MappingSize;
