import lazyLoader from 'helpers/lazy-loader';

const UserDetailLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "user-detail" */ './user-detail')
);

const UserDetail = (props: any) => {
  return <UserDetailLoadable {...props} />;
};

export default UserDetail;
