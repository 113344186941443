import { fetchAuth } from 'api/config';

export const getUserDetail = async () => {
  return await fetchAuth({
    url: '/v1/auth/me',
    method: 'GET'
  });
};

export interface LoginParams {
  email: string;
  password: string;
}

export const doLogin = async (payload: LoginParams) => {
  return await fetchAuth({
    url: '/v1/auth/login',
    method: 'POST',
    body: payload
  });
};
