import { useQuery } from '@tanstack/react-query';

// import { normalizeMetadata } from 'api/config';
import { GetBrandReportParam, getBrandReport } from 'api/services/brand-report';
import { getSeasonList, getSupplierList } from 'api/services/common';

import { RQ_BRAND_REPORT, RQ_COMMON_SEASON_LIST, RQ_COMMON_SUPPLIER_LIST } from 'helpers/constants';

export const useBrandReport = (params: GetBrandReportParam) => {
  const brandReport = useQuery({
    queryKey: [RQ_BRAND_REPORT, params.filters],
    queryFn: async () => await getBrandReport(params),
    select: (res) => ({
      ...res
    })
  });

  const season = useQuery({
    queryKey: [RQ_COMMON_SEASON_LIST],
    queryFn: getSeasonList
  });

  const supplier = useQuery({
    queryKey: [RQ_COMMON_SUPPLIER_LIST],
    queryFn: getSupplierList
  });

  return {
    brandReport,
    season,
    supplier
  };
};
