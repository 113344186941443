import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';

import Breadcrumb from 'components/breadcrumb';
import Combobox from 'components/combobox';
import { SelectValue } from 'components/combobox/types';

import { useBrandReport } from './brand-report.hooks';

const BrandReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const qsSeason = searchParams.get('season');
  const qsSupplier = searchParams.get('supplier');
  const endDateDefault = dayjs().format('YYYY-MM-DD');
  const startDateDefault = dayjs().subtract(7, 'days').format('YYYY-MM-DD');
  const [dateValue, setDateValue] = useState({
    startDate: startDateDefault,
    endDate: endDateDefault
  });

  const handleDateChange = (newValue: any) => {
    setDateValue(newValue);
  };

  const filtersQuery = useMemo(() => {
    const filters = [];

    if (dateValue?.startDate && dateValue?.endDate) {
      filters.push(
        {
          field: 'created_at',
          operator: '>=',
          value: dateValue?.startDate
        },
        {
          field: 'created_at',
          operator: '<=',
          value: dateValue?.endDate
        }
      );
    }

    if (qsSeason) {
      filters.push({
        field: 'season_id',
        operator: '=',
        value: qsSeason
      });
    }

    if (qsSupplier) {
      filters.push({
        field: 'supplier_id',
        operator: '=',
        value: qsSupplier
      });
    }

    return filters;
  }, [qsSeason, qsSupplier, dateValue]);

  const {
    brandReport: { data },
    season: { data: seasonData },
    supplier: { data: supplierData }
  } = useBrandReport({
    filters: filtersQuery
  });

  const handleOnChangeFilter = (field: string, value: SelectValue) => {
    // if null remove query param
    if (value) {
      searchParams.set(field, String(value));
    } else {
      searchParams.delete(field);
    }
    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  return (
    <>
      <Breadcrumb pageName="Brand Report" />
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="mt-7">
          <Datepicker
            value={dateValue}
            onChange={handleDateChange}
            showShortcuts={true}
            placeholder={'Select Date'}
            inputClassName="relative bg-gray-50 py-3 pl-4 pr-14 w-full border-gray-300 text-gray-900 dark:bg-gray-700 dark:text-white dark:border-gray-600 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20"
          />
        </div>
        <Combobox
          label="Filter By Season"
          options={seasonData?.data || []}
          onChange={(value) => handleOnChangeFilter('season', value)}
          defaultValue={qsSeason}
          clearable
        />
        <Combobox
          label="Filter Supplier"
          options={supplierData?.data || []}
          onChange={(value) => handleOnChangeFilter('supplier', value)}
          defaultValue={qsSupplier}
          clearable
          searchable
        />
      </div>
      <div className="rounded-sm border border-stroke dark:border-none bg-white dark:bg-boxdark p-4 md:p-6 xl:p-9 mb-8 mt-4">
        <p className="text-lg text-gray-900 dark:text-white font-medium">Top Orders by Gender</p>
        <div className="relative overflow-x-auto mt-4 rounded-md">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-[35%]">
                  Gender
                </th>
                <th scope="col" className="px-6 py-3">
                  Revenue
                </th>
                <th scope="col" className="px-6 py-3">
                  Orders
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.data.top_orders_by_gender &&
                data?.data.top_orders_by_gender.map((order, orderIndex) => (
                  <tr
                    key={`table-col-${orderIndex}`}
                    className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {order.gender || '-'}
                    </th>
                    <td className="px-6 py-4">{order.orders_revenue || '-'}</td>
                    <td className="px-6 py-4">{order.orders_count || '-'}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="rounded-sm border border-stroke dark:border-none bg-white dark:bg-boxdark p-4 md:p-6 xl:p-9 mb-8">
        <p className="text-lg text-gray-900 dark:text-white font-medium">Top Orders by Country</p>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-[35%]">
                Country
              </th>
              <th scope="col" className="px-6 py-3">
                Revenue
              </th>
              <th scope="col" className="px-6 py-3">
                Orders
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data.top_orders_by_gender &&
              data?.data.top_orders_by_country.map((order, orderIndex) => (
                <tr
                  key={`table-col-${orderIndex}`}
                  className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.user_shipping_country || '-'}
                  </th>
                  <td className="px-6 py-4">{order.orders_revenue || '-'}</td>
                  <td className="px-6 py-4">{order.orders_count || '-'}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="rounded-sm border border-stroke dark:border-none bg-white dark:bg-boxdark p-4 md:p-6 xl:p-9 mb-8">
        <p className="text-lg text-gray-900 dark:text-white font-medium">Top Brands by Gender</p>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-[20%]">
                BRAND NAME
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Man</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Women</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Unisex</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Total</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data.top_brands_by_gender &&
              data?.data.top_brands_by_gender.map((order, orderIndex) => (
                <tr
                  key={`table-col-${orderIndex}`}
                  className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.brand_name || '-'}
                  </th>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.gender.male.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.gender.male.total_order || '0'}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.gender.female.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.gender.female.total_order || '0'}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.gender.unisex.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.gender.unisex.total_order || '0'}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.total.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.total.total_order || '0'}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="rounded-sm border border-stroke dark:border-none bg-white dark:bg-boxdark p-4 md:p-6 xl:p-9 mb-8">
        <p className="text-lg text-gray-900 dark:text-white font-medium">Top Category by Gender</p>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-[20%]">
                CATEGORY
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Man</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Women</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Unisex</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Total</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data.top_category_by_gender &&
              data?.data.top_category_by_gender.map((order, orderIndex) => (
                <tr
                  key={`table-col-${orderIndex}`}
                  className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.category || '-'}
                  </th>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.gender.male.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.gender.male.total_order || '0'}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.gender.female.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.gender.female.total_order || '0'}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.gender.unisex.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.gender.unisex.total_order || '0'}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.total.total_cost || '-'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.total.total_order || '-'}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="rounded-sm border border-stroke dark:border-none bg-white dark:bg-boxdark p-4 md:p-6 xl:p-9 mb-8">
        <p className="text-lg text-gray-900 dark:text-white font-medium">Top Brands by Country</p>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-[30%]">
                BRAND NAME
              </th>
              <th scope="col" className="px-6 py-3">
                COST
              </th>
              <th scope="col" className="px-6 py-3">
                ORDER
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Total</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data.top_brands_by_country &&
              data?.data.top_brands_by_country.map((order, orderIndex) => (
                <tr
                  key={`table-col-${orderIndex}`}
                  className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.name || '-'}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    ${order.total_cost || '0'}.00
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.total_order || '0'}
                  </th>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.grand_total?.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.grand_total?.total_order || '0'}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="rounded-sm border border-stroke dark:border-none bg-white dark:bg-boxdark p-4 md:p-6 xl:p-9 mb-8">
        <p className="text-lg text-gray-900 dark:text-white font-medium">Top Category by Country</p>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3 w-[30%]">
                BRAND NAME
              </th>
              <th scope="col" className="px-6 py-3">
                COST
              </th>
              <th scope="col" className="px-6 py-3">
                ORDER
              </th>
              <th scope="col" className="px-6 py-3">
                <div className="flex justify-center">Total</div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex justify-center items-center">Cost</div>
                  <div className="flex justify-center items-center">Order</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.data.top_category_by_country &&
              data?.data.top_category_by_country.map((order, orderIndex) => (
                <tr
                  key={`table-col-${orderIndex}`}
                  className="bg-white border-b text-black dark:text-white dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.category || '-'}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    ${order.total_cost || '0'}.00
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {order.total_order || '0'}
                  </th>
                  <td className="px-6 py-4">
                    <div className="grid grid-cols-2">
                      <div className="flex justify-center items-center">
                        ${order.grand_total?.total_cost || '0'}.00
                      </div>
                      <div className="flex justify-center items-center">
                        {order.grand_total?.total_order || '0'}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BrandReport;
