import { stringify } from 'qs';

import { COOKIE_ACCESS_TOKEN } from 'helpers/constants';
import { getCookie } from 'helpers/cookie';

import { IPrepareOptions, IRequestOptions } from './request.interface';

export const prepareOptions = (params?: IPrepareOptions, headers?: any) => {
  const token = getCookie(COOKIE_ACCESS_TOKEN);

  const options: IRequestOptions = {
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${getCookie(COOKIE_ACCESS_TOKEN)}` } : {})
    }
  };

  if (params?.data && typeof params.data === 'string') {
    options.body = params.data;
  }

  return options;
};

interface FetchProps {
  url: string;
  method: 'GET' | 'DELETE' | 'POST' | 'PATCH' | 'PUT';
  params?: any;
  body?: any;
  headers?: any;
  isFile?: boolean;
}

export const fetchAuth = async ({
  url,
  method,
  body,
  headers,
  params,
  isFile = false
}: FetchProps) => {
  const fmtParams = params;
  for (const key in params) {
    if (['filters', 'fields', 'pagination', 'sorts'].includes(key)) {
      if (typeof fmtParams[key] !== 'string') {
        fmtParams[key] = JSON.stringify(params[key]);
      }
    } else {
      fmtParams[key] = params[key];
    }
  }
  const queryString = stringify(fmtParams);

  const baseUrl = import.meta.env.VITE_BASE_URL;
  return await fetch(`${baseUrl}${url}?${queryString}`, {
    method,
    ...prepareOptions({ data: JSON.stringify(body) }, headers)
  }).then(async (res) => {
    if (!res.ok) {
      const errorData = {
        status: res.status,
        ...(await res.json())
      };
      throw errorData;
    }

    if (isFile) {
      return await res.blob();
    }
    return await res.json();
  });
};

export const fetchAlex = async ({
  url,
  method,
  body,
  headers,
  params,
  isFile = false
}: FetchProps) => {
  const fmtParams = params;
  for (const key in params) {
    if (['filters', 'fields', 'pagination', 'sorts'].includes(key)) {
      if (typeof fmtParams[key] !== 'string') {
        fmtParams[key] = JSON.stringify(params[key]);
      }
    } else {
      fmtParams[key] = params[key];
    }
  }
  const queryString = stringify(fmtParams);

  const baseUrl = import.meta.env.VITE_ALEX_BASE_URL;

  return await fetch(`${baseUrl}${url}?${queryString}`, {
    method,
    ...prepareOptions({ data: JSON.stringify(body) }, headers)
  }).then(async (res: Response & { Message?: string }) => {
    if (!res.ok) {
      const errorData = {
        status: res.status,
        ...(await res.json())
      };
      errorData.message = errorData.Message;
      throw errorData;
    }
    return await res.json();
  });
};
export const fetchBaltini = async ({ url, method, params }: FetchProps) => {
  const baseUrl = import.meta.env.VITE_BALTINI_BASE_URL;
  const r = new XMLHttpRequest();
  const fmtParams = params;
  for (const key in params) {
    if (['filters', 'fields', 'pagination', 'sorts'].includes(key)) {
      if (typeof fmtParams[key] !== 'string') {
        fmtParams[key] = JSON.stringify(params[key]);
      }
    } else {
      fmtParams[key] = params[key];
    }
  }
  const queryString = stringify(fmtParams);
  r.open(method, `${baseUrl}${url}?${queryString}`, true);
  r.send();

  return await new Promise((resolve, reject) => {
    r.onreadystatechange = function () {
      if (r.readyState === 4) {
        if (r.status === 200) {
          resolve(JSON.parse(r.responseText));
        } else {
          reject(new Error('Failed to fetch data'));
        }
      }
    };
  });
};

export interface Metadata {
  current_page: number;
  keyword: string;
  total_page: number;
  per_page: number;
  total_data: number;
}

export const normalizeMetadata = (metadata: Metadata) => ({
  current: metadata.current_page,
  totalPages: metadata.total_page,
  perPage: metadata.per_page,
  total: metadata.total_data
});
