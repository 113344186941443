import lazyLoader from 'helpers/lazy-loader';

const ProductTagLoadable = lazyLoader(
  async () => await import(/* webpackChunkName: "product-tag-automation" */ './product-tag')
);

const ProductTagAutomation = (props: any) => {
  return <ProductTagLoadable {...props} />;
};

export default ProductTagAutomation;
