import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Swal from 'sweetalert2';

import ProtectedRoute from 'components/protected-route';

import { IErrorResponseExtend } from 'api/response.interface';

import { COOKIE_ACCESS_TOKEN } from 'helpers/constants';
import { setCookie } from 'helpers/cookie';

import { AuthContextProvider } from 'context/AuthContext';

import Loader from './components/loader';
import Login from './pages/login';
import routes from './routes';

const DefaultLayout = lazy(async () => await import('./layout/DefaultLayout'));

const handleError = (res: any) => {
  if (res.status === 401 && window.location.pathname !== '/login') {
    setCookie(COOKIE_ACCESS_TOKEN, '', 0);
    window.location.replace('/');
  } else if (res.status === 400) {
    Swal.fire({
      title: 'Bad Request',
      text: res.message,
      icon: 'error',
      timer: 2500,
      showConfirmButton: false
    });
  } else if (res.status === 500) {
    Swal.fire({
      title: 'Server Error',
      text: 'Please contact Riza to report the issue',
      icon: 'error',
      timer: 2500,
      showConfirmButton: false
    });
  }
};

const MAX_RETRIES = 1;
const HTTP_STATUS_TO_NOT_RETRY = [401];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        const ApiError = error as IErrorResponseExtend;

        if (
          Object.hasOwnProperty.call(ApiError, 'status') &&
          HTTP_STATUS_TO_NOT_RETRY.includes(ApiError.status)
        ) {
          return false;
        }

        return true;
      }
    }
  },
  queryCache: new QueryCache({
    onError: handleError
  }),
  mutationCache: new MutationCache({
    onError: handleError
  })
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route element={<DefaultLayout />}>
            {routes.map((routes, index) => {
              const { path, component: Component } = routes;
              return (
                <Route
                  key={index}
                  path={path}
                  element={
                    <Suspense fallback={<Loader />}>
                      <ProtectedRoute>
                        <Component />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />
              );
            })}
          </Route>
        </Routes>
        <Loader />
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
